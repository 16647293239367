
.slider {
    overflow: 'hidden';
    padding: 2rem 0;
}

.slider img {
    width: 100%;
}


.custom-dot-list-style button{
    border: none;
    background: grey;
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button{
    background: white !important;
}