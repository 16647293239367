.leaflet-popup-content {
    margin: 0 !important;
    padding: 0 !important;
    minWidth: 150px !important;
}

.leaflet-popup-content-wrapper {
    padding: 0;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}
.leaflet-container {
    background: #000
}
.leaflet-tooltip {
    background-color: transparent;
    border: none;
    color: white;
    font-weight: 700;
    box-shadow: none;
}
.leaflet-tooltip::before {
    content: none;
}
.circle-icon {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 50%;
}
.custom-div-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
