.image-gallery-image {
    width: auto;
    max-height: 100px;
    /*height: 500px;*/
    object-fit: cover;
}

.image-gallery-slide img {
    width: 100%;
    /*height: auto;*/
    /*object-fit: cover;*/
}

.image-gallery-thumbnail-image {
    width: 100px;
    height: 60px;
    object-fit: cover;
}

.image-gallery-bullet {
    width: 8px;
    height: 8px;
    background-color: transparent!important;
}

.image-gallery-bullet.active {
    width: 10px;
    height: 10px;
    border: none!important;
    background-color: #36c9a2!important;
    border: #36c9a2!important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    /*max-height: 60vh;*/
}

.react-multi-carousel-item.react-multi-carousel-item--active > div {
    margin-left: 0!important;
}
