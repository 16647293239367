.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #36c9a2;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.small-top-loader-container {
    position: absolute; /* Set position to absolute */
    top: -400px; /* Align to the top of the nearest positioned ancestor */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust to truly center */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Optional: makes sure it stretches across */
    height: 100%; /* Optional: makes sure it covers the full height of the parent */
    background-color: transparent; /* Ensure background is transparent */
    z-index: 1000; /* Set a high z-index to overlay on other content */
}

.small-top-loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #36c9a2; /* Green top border */
    border-radius: 50%; /* Circular shape */
    width: 60px; /* Reduced width */
    height: 60px; /* Reduced height */
    animation: spin 1.5s linear infinite; /* Faster spin */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
