.editor-border {
    /*border: 1px solid #F1F1F1;*/
    border-bottom: none;
}

.toolbarClassName {
    background: transparent!important;
    border: none;
}

.comment-title {
    display: none;
}

.hr-style {
    display: none;
}

.replyBtn {
    display: none;
}

.overlay {
    margin-bottom: 30px;
}

.no-comDiv {
    display: none!important;
}

.react-responsive-modal-modal {
    background-color: #333333!important;
}

.react-responsive-modal-closeButton {
    display: none!important;
}
