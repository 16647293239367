li.ais-InfiniteHits-item::marker {
    display: none
}

.ais-SearchBox-input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}

.ais-SearchBox-input:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.ais-InfiniteHits-loadMore {
    display: none;
}

.ais-InfiniteHits-loadMore.ais-InfiniteHits-loadMore--disabled {
    display: none;
}

.ais-SearchBox-submit {
    display: none;
}

.ais-SearchBox-reset {
    display: none;
}

ol {
    list-style-type: none;
    padding: 0;
}
