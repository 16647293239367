/* Shaking animation */
.shaking {
    animation: shake 0.5s infinite; /* Shake only for 3 seconds */
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

/* Flip animation */
.flip-box {
    background-color: transparent;
    width: 100%;
    height: 79vh;
    border: none;
    perspective: 1000px;
}

.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s; /* Flip duration */
    transform-style: preserve-3d;
}

.flip-box-inner.flipped {
    transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-box-front {
    background-color: transparent;
    color: black;
}

.flip-box-back {
    background-color: transparent;
    color: white;
    transform: rotateY(180deg);
}

.flip-box-front img {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.flip-box-front img:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

/* Shake-It Container */
.shake-it {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 20px auto;
}

.message {
    font-size: 18px;
    font-weight: 600;
}

.reward {
    font-size: 24px;
    font-weight: bold;
    color: #36c9a2;
    margin: 10px 0;
}

.note {
    font-size: 16px;
    margin-top: 10px;
}